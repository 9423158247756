<template>
	<b-modal
		id="add-generic-category-modal"
		size="lg"
		title="Generic Category"
		ok-only
		ok-title="Done"
	>
		<div v-if="tempSubCategory">
			<div class="mb-4">
				<label v-if="tempSubCategory.genericCategories.length" class="mb-1">
					Added categories:
				</label>
				<p
					v-for="(gen, index) in tempSubCategory.genericCategories"
					:key="gen.id"
					class="mb-0"
				>
					{{ index + 1 }}. {{ gen.productGenericCategoryNameGeo }} /
					{{ gen.productGenericCategoryNameEng }}
				</p>
			</div>
			<div>
				<span>{{
					`Generic Category for ${tempSubCategory.productSubCategoryNameEng} / ${tempSubCategory.productSubCategoryNameGeo}`
				}}</span>
				<b-form-group label-for="Generic Category" class="mt-1">
					<div class="">
						<div class="d-flex flex-row mb-1">
							<b-form-input
								v-model="computedTempGeneric.productGenericCategoryNameGeo"
								placeholder="Generic Cat Geo name"
								class="mr-1"
							/>
							<b-form-input
								v-model="computedTempGeneric.productGenericCategoryNameEng"
								placeholder="Generic Cat Eng name"
							/>
						</div>
						<div>
							<div class="d-flex flex-row mb-2">
								<b-form-select
									v-model="computedTempGeneric.giftType"
									:options="giftType"
									class="gift-type-select w-50"
								/>
								<b-input-group append="%" class="percent-input ml-2 w-50">
									<b-form-input
										v-model="computedTempGeneric.emotyCommissionFeePercent"
										placeholder="0"
									/>
								</b-input-group>
							</div>
						</div>

						<b-button
							variant="primary"
							size="sm"
							class="left-margin"
							:disabled="isBtnDisabled"
							@click="addGenericToSubCategory(tempSubCategory)"
						>
							<feather-icon size="16" icon="PlusIcon" /> Add Generic Category
						</b-button>
					</div>
				</b-form-group>
			</div>
		</div>
	</b-modal>
</template>

<script>
import {
	BFormGroup,
	BFormInput,
	BButton,
	BInputGroup,
	BModal,
	BFormSelect,
} from 'bootstrap-vue';

export default {
	components: {
		BButton,
		BFormGroup,
		BFormSelect,
		BFormInput,
		BInputGroup,
		BModal,
	},
	props: {
		tempGeneric: {
			type: Object,
			default: () => {},
		},
		tempSubCategory: {
			type: Object,
			default: () => {},
		},
		addGenericToSubCategory: {
			type: Function,
			default: () => true,
		},
		giftType: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		computedTempGeneric: {
			get() {
				return this.tempGeneric;
			},
			set(value) {
				this.$emit('tempGeneric', value);
			},
		},
		isBtnDisabled() {
			const temp = this.tempGeneric;
			if (
				temp.productGenericCategoryNameGeo === '' ||
				temp.giftType === null ||
				temp.productGenericCategoryNameEng === ''
			) {
				return true;
			}
			return false;
		},
	},
};
</script>

<style></style>
