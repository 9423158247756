<template>
	<b-card>
		<AddGenericCategoryModal
			:temp-sub-category="tempSubCategory"
			:temp-generic.sync="tempGeneric"
			:add-generic-to-sub-category="addGenericToSubCategory"
			:gift-type="giftType"
		/>
		<div class="">
			<b-button
				size="sm"
				class="ml-auto"
				variant="outline-success"
				style="display: block"
				@click="$router.push('/categories')"
				><feather-icon icon="ArrowLeftCircleIcon" class="mr-25" /> Go Back
			</b-button>
		</div>
		<b-row>
			<b-col md="6" class="padding-zero">
				<b-form-group label="Category Name" label-for="category-name">
					<div class="d-flex">
						<b-form-input
							id="category-name-geo"
							v-model="category.productCategoryNameGeo"
							:readonly="disableCatNames"
							type="text"
							class="mr-1"
							placeholder="Category Geo name"
						/>
						<b-form-input
							id="category-name-eng"
							v-model="category.productCategoryNameEng"
							:readonly="disableCatNames"
							type="text"
							placeholder="Category Eng Name"
						/>
					</div>
				</b-form-group>
				<!--  -->
				<label class="mt-2">Add sub categories:</label>
				<div class="category-container">
					<span>Sub Category name</span>
					<b-form-group abel-for="sub-category-name">
						<div class="d-flex">
							<b-form-input
								v-model="subCategoryNameGeo"
								placeholder="Enter Geo Sub-Category"
								label="Sub Category"
								:disabled="!category.productCategoryNameEng"
								label-for="Sub Category"
								class="mb-2 mr-1"
							/>
							<b-form-input
								v-model="subCategoryNameEng"
								placeholder="Enter Eng Sub-Category"
								label="Sub Category"
								:disabled="!category.productCategoryNameEng"
								label-for="Sub Category"
								class="mb-2"
							/>
						</div>
						<div class="d-flex mb-2 service-type-button">
							<b-form-select
								v-model="selectedServiceType"
								:options="serviceType"
							/>
						</div>
					</b-form-group>
					<b-button
						v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						class="sub-category-button mb-2"
						variant="primary"
						:disabled="!selectedServiceType"
						@click="addSubCategoryName"
					>
						<feather-icon icon="PlusIcon" class="mr-25" />
						<span>Add</span>
					</b-button>
				</div>
				<b-button
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					variant="primary"
					@click="submitCategory"
				>
					<span>Save</span>
				</b-button>
			</b-col>
			<b-col md="6">
				<p>Sub Categories</p>

				<div
					v-for="(subCategory, index) in category.subCategories"
					:key="subCategory.productSubCategoryNameEng + Math.random()"
					class="p-1 rounded border mb-2"
				>
					<div class="d-flex">
						<div>
							<p>
								Title:
								<b>{{
									`${subCategory.productSubCategoryNameGeo}  / ${subCategory.productSubCategoryNameEng}`
								}}</b>
							</p>
							<p>
								Service type:
								<b>{{ serviceTypeList[subCategory.serviceType] }}</b>
							</p>
						</div>
						<div class="ml-auto">
							<b-button
								size="sm"
								variant="outline-success"
								pill
								@click="openGenericModal(subCategory)"
							>
								Add Generic Category
							</b-button>
							<b-button
								size="sm"
								variant="danger"
								class="ml-1"
								@click="category.subCategories.splice(index, 1)"
							>
								<feather-icon icon="TrashIcon" />
							</b-button>
						</div>
					</div>
					<label v-if="subCategory.genericCategories.length">
						Generic Categories of this SubCategory:
					</label>
					<div
						v-for="(genericCategory, index) in subCategory.genericCategories"
						:key="genericCategory.productGenericCategoryNameGeo + Math.random()"
						class="p-1 mb-1 border rounded-sm d-flex justify-content-between"
					>
						<div>
							<div>
								Title:
								<b>{{
									`${genericCategory.productGenericCategoryNameGeo}  / ${genericCategory.productGenericCategoryNameEng}`
								}}</b>
							</div>
							<div>
								GiftType: <b> {{ GiftTypeList[genericCategory.giftType] }}</b>
							</div>
							<div>
								Percentage:<b>
									{{ genericCategory.emotyCommissionFeePercent }}
								</b>
							</div>
						</div>
						<div>
							<b-button
								size="sm"
								variant="danger"
								@click="subCategory.genericCategories.splice(index, 1)"
							>
								<feather-icon icon="TrashIcon" />
							</b-button>
						</div>
					</div>
				</div>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import {
	BFormGroup,
	BFormInput,
	BCol,
	BCard,
	BButton,
	BRow,
	BFormSelect,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { mapActions } from 'vuex';
import AddGenericCategoryModal from './components/AddGenericCategoryModal.vue';

export default {
	components: {
		BCard,
		BCol,
		BRow,
		BButton,
		BFormGroup,
		BFormSelect,
		BFormInput,
		AddGenericCategoryModal,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			disableCatNames: false,
			tempSubCategory: null,
			serviceTypeList: {
				1: 'Experimental',
				2: 'Material',
				3: 'Digital',
				4: 'Card',
			},
			GiftTypeList: {
				1: 'Both',
				2: 'Daily',
				3: 'Holiday',
			},
			serviceType: [
				{ value: null, text: 'Please select a service type' },
				{
					value: 1,
					text: 'Experiential',
				},
				{
					value: 2,
					text: 'Material',
				},
				{
					value: 3,
					text: 'Digital',
				},
				{
					value: 4,
					text: 'Card',
				},
			],
			giftType: [
				{ value: null, text: 'Please select a gift type' },
				{
					value: 1,
					text: 'Both',
				},
				{
					value: 2,
					text: 'Daily',
				},
				{
					value: 3,
					text: 'Holiday',
				},
			],
			selectedServiceType: null,
			commisions: [],
			giftTypes: [],
			tempGeneric: {
				productGenericCategoryNameGeo: '',
				productGenericCategoryNameEng: '',
				giftType: null,
				emotyCommissionFeePercent: null,
			},
			subCategoryNameGeo: '',
			subCategoryNameEng: '',
			category: {
				productCategoryNameEng: '',
				productCategoryNameGeo: '',
				subCategories: [],
			},
		};
	},
	methods: {
		...mapActions('parametersModule', ['createCategory']),
		addGenericToSubCategory(subCategory) {
			// PUSH THIS TO existing sub category
			const subCatIndex = this.category.subCategories.indexOf(subCategory);

			this.category.subCategories[subCatIndex].genericCategories.push(
				this.tempGeneric
			);
			// reset the tempgeneric and tempsubcategory
			// this.tempSubCategory = null;
			this.tempGeneric = {
				productGenericCategoryNameGeo: '',
				productGenericCategoryNameEng: '',
				giftType: null,
				emotyCommissionFeePercent: null,
			};

			// this.$bvModal.hide('add-generic-category-modal');
		},
		openGenericModal(subCategory) {
			this.tempSubCategory = subCategory;
			this.$bvModal.show('add-generic-category-modal');
		},
		addSubCategoryName() {
			if (this.subCategoryNameGeo.length) {
				this.category.subCategories.push({
					productSubCategoryNameGeo: this.subCategoryNameGeo,
					productSubCategoryNameEng: this.subCategoryNameEng,
					serviceType: this.selectedServiceType,
					genericCategories: [],
				});
				this.disableCatNames = true;
				this.subCategoryNameGeo = '';
				this.subCategoryNameEng = '';
			}
		},
		selectServiceType(item) {
			this.selectedServiceType = item;
		},
		selectGiftType(item) {
			this.selectedGiftType = item;
		},
		submitCategory() {
			this.createCategory(this.category).then((response) => {
				if (response.data.error === null) {
					this.$router.push('categories');
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.repeater-form {
	overflow: hidden;
	transition: 0.35s height;
}
.inputs-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.b-form-tag .close {
	display: hide !important;
}
.category-container {
	flex-direction: column;
	.sub-category-button {
		width: 100%;
	}
}
.padding-zero {
	padding-left: 0px;
}
.service-type-button {
	width: 100%;
}
.gift-type-select {
	width: 30%;
}
.percent-input {
	width: 25%;
}
</style>
